<script>
  import { call } from 'vuex-pathify'
  import { ApiError } from '@/api'

  export default {
    data() {
      return {
        formRef: null,
        currentPassword: null,
        newPassword: null,
        confirmPassword: null,
      }
    },
    methods: {
      ...call('account', ['changePassword']),
      async submit() {
        try {
          await this.formRef.process(
            this.changePassword({
              currentPassword: this.currentPassword,
              newPassword: this.newPassword,
              confirmPassword: this.confirmPassword,
            })
          )
          this.$navigate({ name: 'AccountProfileSettings' })
        } catch (error) {
          if (error instanceof ApiError) {
            return
          }
          return Promise.reject(error)
        }
      },
    },
  }
</script>

<template>
  <section>
    <BaseForm v-slot="{ submitEnabled, errorMessage }" v-ref="formRef" spinner @submit="submit">
      <BaseHeading size="h5" tag="h2" class="mb-6">Change Password</BaseHeading>

      <BasePanelWarning v-if="errorMessage" class="mb-6">
        <div v-if="errorMessage === 'Wrong current password'">
          We were unable to validate your current password.
          <BaseLinkStyled :to="{ name: 'AccountResetPassword' }">
            Use this form to reset your password.
          </BaseLinkStyled>
        </div>
        <div v-else>
          {{ errorMessage }}
        </div>
      </BasePanelWarning>

      <BaseStack>
        <BaseInput
          v-model="currentPassword"
          name="currentPassword"
          type="password"
          required
          data-dd-privacy="mask"
        >
          Current Password
        </BaseInput>
        <BaseInput
          v-model="newPassword"
          name="newPassword"
          type="password"
          required
          data-dd-privacy="mask"
        >
          New Password
        </BaseInput>
        <BaseInput
          v-model="confirmPassword"
          name="confirmPassword"
          type="password"
          required
          data-dd-privacy="mask"
        >
          Confirm New Password
        </BaseInput>
      </BaseStack>

      <BaseButton type="submit" :disabled="!submitEnabled" class="mt-6"> Save </BaseButton>
    </BaseForm>

    <div class="mt-6 -mb-2 flex justify-center">
      <BaseLinkStyled :to="{ name: 'AccountProfileSettings' }" color="gray" font-size="xs">
        Cancel
      </BaseLinkStyled>
    </div>
  </section>
</template>
